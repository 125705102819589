interface Props {
  className?: string;
  style?: any;
}
export default function BlueskyIcon(props: Props) {
  const {
    className,
    style
  } = props;
  const otherProps = ({} as any);
  return <svg fill="none" viewBox="0 0 64 57" width="32" height="28.5" className={className} style={style} title="Bluesky Logo" {...otherProps} data-sentry-element="svg" data-sentry-component="BlueskyIcon" data-sentry-source-file="BlueskyIcon.tsx">
      <path
    // fill="#0085ff"
    d="M13.873 3.805C21.21 9.332 29.103 20.537 32 26.55v15.882c0-.338-.13.044-.41.867-1.512 4.456-7.418 21.847-20.923 7.944-7.111-7.32-3.819-14.64 9.125-16.85-7.405 1.264-15.73-.825-18.014-9.015C1.12 23.022 0 8.51 0 6.55 0-3.268 8.579-.182 13.873 3.805ZM50.127 3.805C42.79 9.332 34.897 20.537 32 26.55v15.882c0-.338.13.044.41.867 1.512 4.456 7.418 21.847 20.923 7.944 7.111-7.32 3.819-14.64-9.125-16.85 7.405 1.264 15.73-.825 18.014-9.015C62.88 23.022 64 8.51 64 6.55c0-9.818-8.578-6.732-13.873-2.745Z" data-sentry-element="path" data-sentry-source-file="BlueskyIcon.tsx" />
    </svg>;
}
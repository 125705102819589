interface Props {
  style?: any;
  className?: string;
}
export default function InstagramIcon(props: Props) {
  const {
    className,
    style
  } = props;
  const otherProps = ({} as any);
  return <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" style={style} className={className} {...otherProps} data-sentry-element="svg" data-sentry-component="InstagramIcon" data-sentry-source-file="InstagramIcon.tsx">
      <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" data-sentry-element="path" data-sentry-source-file="InstagramIcon.tsx" />
    </svg>;
}
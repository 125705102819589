// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { useEffect, useState } from "react";
import { bulkUpdateContentItems, createContentItem2, deleteContentItem, updateContentItem2 } from "lib/gf-api/content-api";
import { useApiCallParams, useMemberJwt } from "hooks";
import { ContentItem, ContentItemExtended, ContentItemParams, MiniContentItem } from "types/content";
import NavItemForm from "./NavItemForm";
import NavItemList from "./NavItemList";
import Flash from "components/Flash";
import { observer } from "mobx-react-lite";
import ListIcon from "components/icon/ListIcon";
import EditPropertiesIcon from "components/icon/EditPropertiesIcon";
import ContentItemTypeIcon from "./ContentItemTypeIcon";
import TitleWrapper from "./modal/TitleWrapper";
import TitleHeading from "./modal/TitleHeading";
import TitleSubHeading from "./modal/TitleSubHeading";
import { Tab, TabContainer } from "components/tab";
import BackButtonWithLabel from "components/ui/BackButtonWithLabel";
import { useContentEditStore, useContentStore } from "stores";
function NavEdit() {
  const [apiError, setApiError]: [any, (v: any) => void] = useState();
  const contentStore = useContentStore();
  const contentEditStore = useContentEditStore();
  const stackItem1 = contentEditStore?.currentNavItem();
  if (!stackItem1) {
    throw new Error("No currentNavItem found");
  }
  const stackItem = stackItem1;
  const navItemId = stackItem.contentId;
  const navItem = stackItem.contentItem;
  const initialParams = stackItem.initialParams;
  const values: Partial<MiniContentItem> = navItem || initialParams || {};
  const jwt = (useMemberJwt() as string);
  const mode = stackItem.baseTypeEditMode || "list";
  const [successFlash, setSuccessFlash] = useState<null | string>(null);
  const apiProps = useApiCallParams();
  if (!jwt) return null;
  async function onUpdateNavItem(params: Partial<ContentItemParams>) {
    if (!navItem) {
      throw new Error("Expected navItem");
    }
    resetMessages();
    params = {
      id: navItem.id,
      ...params
    };
    const result = await updateContentItem2(apiProps, params);
    if (result.error) {
      setApiError(result);
    } else {
      setSuccessFlash("Updated nav item");
      loadNavItemExtended();
      contentStore.reloadNavigationData({
        useApi: true
      });
      onReturnFromEdit(result.data);
    }
  }
  async function onCreateNavItem(params: Partial<ContentItemParams>) {
    if (!initialParams) {
      throw new Error("initialParams is not set");
    }
    resetMessages();
    params = {
      ...initialParams,
      ...params
    };
    const result = await createContentItem2(apiProps, params);
    if (result.error) {
      setApiError(result);
    } else {
      setSuccessFlash("Created nav item");
      loadNavItemExtended(result.data.id);
      contentStore.reloadNavigationData({
        useApi: true
      });
      onReturnFromEdit(result.data);
    }
  }
  async function loadNavItemExtended(idArg?: string): Promise<ContentItemExtended | null> {
    if (!contentEditStore) return null;
    let id: string;
    if (idArg) {
      id = idArg;
    } else {
      if (!navItem) {
        throw new Error("Expected navItem to be set");
      }
      id = navItem.id;
    }
    const result = await contentEditStore.getContentItem(id);
    if (result.error) {
      setApiError(result);
      return null;
    } else {
      const stackItem = contentEditStore.currentNavItem();
      if (!stackItem) {
        contentEditStore.pushStack(result.data);
      } else {
        contentEditStore.updateCurrentNavItem({
          ...stackItem,
          contentItem: result.data
        });
      }
      return result.data;
    }
  }
  async function onReorder(ids: string[]) {
    resetMessages();
    if (!navItem) {
      throw new Error("Expected navItem");
    }
    const paramsList = ids.map((id, order) => ({
      id,
      order
    }));
    const result = await bulkUpdateContentItems(apiProps, paramsList);
    if (result.error) {
      setApiError(result);
    }
    loadNavItemExtended();
    contentStore.reloadNavigationData({
      useApi: true
    });
  }
  async function onItemClick(selectedNavItem: ContentItem) {
    if (!contentEditStore) return null;
    if (!navItem) {
      throw new Error("Expected navItem");
    }
    resetMessages();
    const baseTypeEditMode = selectedNavItem.plurality === "item" ? "edit" : "list";
    const contentId = selectedNavItem.id;
    contentEditStore.pushStack({
      baseType: "nav",
      baseTypeEditMode,
      contentId
    });
  }
  function resetMessages() {
    setApiError(null);
    setSuccessFlash(null);
  }
  async function onItemDelete(selectedNavItem: ContentItem) {
    resetMessages();
    if (!navItem) {
      throw new Error("Expected navItem");
    }
    const result = await deleteContentItem(apiProps, selectedNavItem.id);
    if (result.error) {
      setApiError(result);
    } else {
      setSuccessFlash("Deleted nav item");
      loadNavItemExtended();
      contentStore.reloadNavigationData({
        useApi: true
      });
    }
  }
  function onBackButtonClick() {
    resetMessages();
    transitionBack();
  }
  function onReturnFromEdit(item?: ContentItem) {
    if (!contentEditStore) return;
    item = item || navItem;
    if (!item) {
      throw new Error("No navItem present");
    }
    const {
      plurality
    } = item;
    if (plurality === "item" || plurality === "list") {
      transitionBack();
    } else if (item.plurality === "multi_list") {
      contentEditStore.updateCurrentNavItem({
        ...stackItem,
        baseTypeEditMode: "list"
      });
    }
  }
  function transitionBack() {
    if (!contentEditStore) return;
    contentEditStore.popNavStack();
    if (contentEditStore.navStack.length > 0) {
      const newStackItem = contentEditStore.currentNavItem();
      newStackItem?.contentId && loadNavItemExtended(newStackItem?.contentId);
    } else {
      contentEditStore.onEditingClose();
    }
  }
  function onStartNewItem() {
    if (!contentEditStore) return;
    contentEditStore.pushStack({
      baseType: "nav",
      baseTypeEditMode: "add",
      initialParams: {
        parentId: navItem?.id,
        plurality: values.plurality === "multi_list" ? "list" : "item",
        baseType: "nav"
      }
    });
  }
  useEffect(() => {
    if (!contentEditStore) return;
    if (navItemId && !navItem) {
      // TODO There is duplication between this block and loadNavItemExtended()
      contentEditStore.getContentItem(navItemId).then(result => {
        if (!result.error) {
          const {
            plurality
          } = result.data;
          if (plurality === "item") {
            contentEditStore.updateCurrentNavItem({
              ...stackItem,
              baseTypeEditMode: "edit",
              contentItem: result.data
            });
          } else {
            contentEditStore.updateCurrentNavItem({
              ...stackItem,
              baseTypeEditMode: "list",
              contentItem: result.data
            });
          }
        }
      });
    }
  }, [navItemId]);
  if (!contentEditStore) return null;
  return <div data-sentry-component="NavEdit" data-sentry-source-file="NavEdit.tsx">
      <>
        <TitleWrapper data-sentry-element="TitleWrapper" data-sentry-source-file="NavEdit.tsx">
          <BackButtonWithLabel onClick={onBackButtonClick} className="mr-3" data-sentry-element="BackButtonWithLabel" data-sentry-source-file="NavEdit.tsx" />
          <TitleHeading data-sentry-element="TitleHeading" data-sentry-source-file="NavEdit.tsx">
            {navItem ? <>
                <ContentItemTypeIcon contentItem={navItem || initialParams || {}} />
                <div title={values.id ? `ID: ${values.id}` : undefined}>
                  {values.label}
                </div>
                <TitleSubHeading>{values.internalName}</TitleSubHeading>
              </> : <>
                <ContentItemTypeIcon contentItem={values} />
                <div className="flex-grow">New nav {values.plurality}</div>
              </>}
          </TitleHeading>
        </TitleWrapper>

        {navItem && <TabContainer>
            {values.plurality !== "item" && <Tab onClick={() => {
          resetMessages();
          contentEditStore.updateCurrentNavItem({
            ...stackItem,
            baseTypeEditMode: "list"
          });
        }} active={mode === "list"} className="flex flex-row gap-1 items-center text-gray-darker">
                <ListIcon className="fill-current" style={{
            width: "18px",
            height: "18px",
            marginLeft: "-2px"
          }} />
                <div>List</div>
              </Tab>}
            <Tab onClick={() => {
          resetMessages();
          contentEditStore.updateCurrentNavItem({
            ...stackItem,
            baseTypeEditMode: "edit"
          });
        }} active={mode === "edit"} className="flex flex-row gap-1 items-center">
              <EditPropertiesIcon className="fill-current text-gray-darker" style={{
            width: "18px",
            height: "18px",
            marginLeft: "-2px"
          }} />
              Attributes
            </Tab>
          </TabContainer>}

        {mode === "add" && <>
            <NavItemForm className="mt-4" onClose={transitionBack} onSave={onCreateNavItem} formValues={values} />
          </>}

        {mode === "edit" && navItem && <>
            <NavItemForm className="mt-4" navItem={navItem} onClose={onReturnFromEdit} onSave={onUpdateNavItem} />
          </>}

        {mode === "list" && <>
            <div className="mt-4">
              {successFlash && <Flash className="mb-2" textClass="text-white" onClose={() => setSuccessFlash(null)}>
                  {successFlash}
                </Flash>}
              {navItem && <NavItemList navItem={navItem} onItemClick={onItemClick} onItemRemove={onItemDelete} onReorder={onReorder} onNewClick={onStartNewItem} />}
            </div>
          </>}
      </>

      {apiError && <Flash type="warning" onClose={() => setApiError(null)} className="mt-4">
          There was an error while servicing your request. Please try again.
        </Flash>}
    </div>;
}
export default observer(NavEdit);
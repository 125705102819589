/**
 * Page bootstrap.
 *
 * Sets up ApplicationContext for the page.
 */

import type { AppProps } from "next/app";
import ReactModal from "react-modal";
import { useCookies } from "react-cookie";
import * as Sentry from "@sentry/browser";
import { getApiBaseUrl, getWebSocketBaseUrl } from "lib/gf-api/api-util";
import AppContextType, { InitialPageProps, PagesAppSetupReturn } from "types/gf-app-context";
import { jwtCookieName, sessionCookieName } from "lib/constants";
import token from "lib/token";
import { useEffect } from "react";
import { initializeApolloClient } from "lib/gf-api/apollo";
import { calculateThemeInfo } from "lib/theme";
import { useRouter } from "next/router";
import { PageLayout } from "components/layout";
import useTrackMarketingActor from "./useTrackMarketingActor";
import { RootStore } from "stores";
type AppProps2 = AppProps & {
  pageProps: InitialPageProps;
};

/**
 * Bootstrap the application.
 *
 * This is used by pages/_app.tsx.
 */
export default function useAppBootstrap(appProps: AppProps2): PagesAppSetupReturn {
  const {
    Component,
    pageProps
  } = appProps;
  let {
    jwt,
    uri,
    groupSlug,
    groupConfig,
    groups
  } = pageProps;
  const router = useRouter();
  const path = router.asPath;
  const [cookies, setCookie] = useCookies();
  jwt = jwt || cookies[jwtCookieName];
  const {
    sessionId
  } = useSetSessionId(cookies, setCookie);
  const {
    marketingId
  } = useTrackMarketingActor([cookies, setCookie]);

  // Re-fetch pageProps from serialized __NEXT_DATA__ if needed
  if (!groupConfig && typeof window !== "undefined") {
    const el = window.document.getElementById("__NEXT_DATA__");
    if (el?.textContent) {
      const data = JSON.parse(el.textContent);
      const pageProps = data.props.pageProps;
      groupSlug = pageProps.groupSlug;
      groupConfig = pageProps.groupConfig;
      uri = pageProps.uri;
      groups = pageProps.groups;
    }
  }
  if (groupConfig) {
    Sentry.setContext("group", {
      slug: groupConfig.slug,
      name: groupConfig.name
    });
  }
  const apiBaseUrl = groupSlug && uri && getApiBaseUrl(uri);
  const wsBaseUrl = groupSlug && uri && getWebSocketBaseUrl(uri);

  // Initialize root store
  let rootStore;
  if (typeof window !== "undefined" && (window as any)?.gfRootStore) {
    rootStore = (window as any).gfRootStore;
  } else {
    rootStore = new RootStore({
      apiBaseUrl,
      wsBaseUrl,
      jwt,
      groupSlug,
      groupConfig
    });
    // Attach to window for debugging
    if (typeof window !== "undefined") {
      (window as any).gfRootStore = rootStore;
    }
  }
  const apollo = apiBaseUrl && groupSlug && initializeApolloClient(apiBaseUrl, rootStore.jwtStore, groupSlug, sessionId) || undefined;
  ReactModal.setAppElement("#__next");

  // Setup layout
  let getLayout;
  let metaData;
  if ((Component as any).getLayout) {
    getLayout = (Component as any).getLayout;
  } else if (groupSlug) {
    getLayout = (page: React.ReactNode) => <PageLayout>{page}</PageLayout>;
  } else {
    getLayout = (page: React.ReactNode) => page;
  }
  let contentShowControls = true;
  if ((Component as any).metaData) {
    metaData = (Component as any).metaData();
    if (metaData.contentShowControls === false) {
      contentShowControls = false;
    }
  }

  // Create app context with root store
  const appContext: AppContextType = {
    alertStore: rootStore.alertStore,
    apollo,
    channelStore: rootStore.channelStore,
    contentEditStore: rootStore.contentEditStore,
    contentShowControls,
    emailSetModalStore: rootStore.emailSetModalStore,
    formStore: rootStore.formStore,
    groupConfig,
    groups,
    groupSlug,
    hamburgerStore: rootStore.hamburgerStore,
    jwt,
    jwtStore: rootStore.jwtStore,
    loginModalStore: rootStore.loginModalStore,
    marketingId,
    passwordSetModalStore: rootStore.passwordSetModalStore,
    rootStore,
    sessionId,
    stripeStore: rootStore.stripeStore,
    themeInfo: calculateThemeInfo(path),
    uri,
    userStore: rootStore.userStore
  };
  return {
    getLayout,
    component: (Component as any),
    appContext
  };
}
function useSetSessionId(cookies: any, setCookie: any) {
  if (typeof window === "undefined") {
    return {
      sessionId: null
    };
  }
  const cookieConfig = {
    path: "/",
    sameSite: true,
    secure: true
  };
  let sessionId = cookies[sessionCookieName];
  useEffect(() => {
    if (!sessionId) {
      sessionId = token();
      setCookie(sessionCookieName, sessionId, cookieConfig);
    }
  }, [sessionId]);
  return {
    sessionId
  };
}
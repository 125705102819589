/**
 * Default <main> element for a page, that individual page components can use.
 */

import React, { useEffect, useState } from "react";
import { ApiErrorState } from "types/api";
import GeneralApiError from "components/GeneralApiError";
import { observer } from "mobx-react-lite";
import { useLoginModalStore, useUserStore } from "stores";
import disallowedContent, { getAccessStatus } from "components/admin/members/statusMessaging/disallowedContent";
import PageHeading from "components/ui/PageHeading";
import { AccessRequirements } from "types/auth";
interface Props {
  accessRequirements?: AccessRequirements;
  apiError?: ApiErrorState;
  backButtonHref?: string;
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  rightSideContent?: React.ReactNode;
  sectionClass?: string;
  title?: React.ReactNode | string;
  withGroupFlow?: boolean;
  withXPadding?: boolean;
}
function MainContent(props: Props) {
  const {
    accessRequirements,
    backButtonHref,
    children,
    apiError,
    fullWidth,
    rightSideContent,
    title,
    withGroupFlow,
    withXPadding
  } = props;
  const [errorClosed, setErrorClosed]: [boolean, (value: boolean) => void] = useState((false as boolean));
  const userStore = useUserStore();
  const member = userStore.user;
  const loginModalStore = useLoginModalStore();
  let className = "MainContent";
  if (props.className) {
    className = className + " " + props.className;
  }
  className = className + " grow";
  let sectionClass = props.sectionClass || "w-full mx-auto py-8";
  if (!fullWidth) {
    sectionClass = sectionClass + " max-w-[900px]";
  }
  let accessStatus = getAccessStatus({
    requirements: accessRequirements,
    userStore
  });
  let notAllowedContent = disallowedContent({
    requirements: accessRequirements,
    userStore
  });
  if (accessStatus === "loginRequired") {
    notAllowedContent = <>Login Required</>;
  }
  useEffect(() => {
    if (!loginModalStore) {
      return;
    }
    if (accessStatus === "loginRequired" && !loginModalStore.isOpen) {
      loginModalStore.open();
    } else if (loginModalStore.isOpen) {
      loginModalStore.close();
    }
  }, [accessStatus, member]);
  const content = notAllowedContent || children;
  return <main className={className} data-sentry-component="MainContent" data-sentry-source-file="MainContent.tsx">
      <GeneralApiError apiError={errorClosed ? null : apiError} onClose={() => setErrorClosed(true)} data-sentry-element="GeneralApiError" data-sentry-source-file="MainContent.tsx" />
      <section className={sectionClass}>
        <PageHeading backButtonHref={backButtonHref} rightSideContent={rightSideContent} paddingClass="px-4" withGroupFlow={withGroupFlow} data-sentry-element="PageHeading" data-sentry-source-file="MainContent.tsx">
          {title}
        </PageHeading>
        {withXPadding ? <div className="px-4">{content}</div> : content}
      </section>
    </main>;
}
export default observer(MainContent);
import { RootStore } from "./RootStore";
import { useAppContext } from "lib/gf-app-context";

export function useStores(): RootStore {
  const { rootStore } = useAppContext();
  if (!rootStore) {
    throw new Error("No RootStore available");
  }
  return rootStore;
}

// Helper hooks for commonly used stores
export function useUserStore() {
  const { userStore } = useStores();
  if (!userStore) throw new Error("UserStore is not available");
  return userStore;
}

export function useContentStore() {
  const { contentStore } = useStores();
  if (!contentStore) throw new Error("ContentStore is not available");
  return contentStore;
}

export function useContentEditStore() {
  const { contentEditStore } = useStores();
  if (!contentEditStore) throw new Error("ContentEditStore is not available");
  return contentEditStore;
}

export function useAlertStore() {
  const { alertStore } = useStores();
  if (!alertStore) throw new Error("AlertStore is not available");
  return alertStore;
}

export function useLoginModalStore() {
  const { loginModalStore } = useStores();
  if (!loginModalStore) throw new Error("LoginModalStore is not available");
  return loginModalStore;
}

export function useWebSocketStore() {
  const { webSocketStore } = useStores();
  if (!webSocketStore) throw new Error("WebSocketStore is not available");
  return webSocketStore;
}

export function useFormStore() {
  const { formStore } = useStores();
  if (!formStore) throw new Error("FormStore is not available");
  return formStore;
}

export function useJwtStore() {
  const { jwtStore } = useStores();
  if (!jwtStore) throw new Error("JwtStore is not available");
  return jwtStore;
}

export function useStripeStore() {
  const { stripeStore } = useStores();
  if (!stripeStore) throw new Error("StripeStore is not available");
  return stripeStore;
}

export function useChannelStore() {
  const { channelStore } = useStores();
  if (!channelStore) throw new Error("ChannelStore is not available");
  return channelStore;
}

export function useHamburgerStore() {
  const { hamburgerStore } = useStores();
  if (!hamburgerStore) throw new Error("HamburgerStore is not available");
  return hamburgerStore;
}

export function usePasswordSetModalStore() {
  const { passwordSetModalStore } = useStores();
  if (!passwordSetModalStore)
    throw new Error("PasswordSetModalStore is not available");
  return passwordSetModalStore;
}

export function useEmailSetModalStore() {
  const { emailSetModalStore } = useStores();
  if (!emailSetModalStore)
    throw new Error("EmailSetModalStore is not available");
  return emailSetModalStore;
}

// Re-export RootStore
export { RootStore };

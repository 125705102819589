/**
 * Displays the "Edit" button on most web pages, to start the content editing flow.
 */
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ADMIN_STYLE } from "lib/Style";
import { observer } from "mobx-react-lite";
import { useContentEditStore } from "stores";
import { EditStatus } from "types/content";
interface Props {
  editStatus?: EditStatus;
  onConfirmingHide?: (editing: boolean) => void;
  onSelecting?: (editing: boolean) => void;
}
function GlobalEditControls(props: Props) {
  const {
    onConfirmingHide,
    onSelecting,
    editStatus
  } = props;
  const contentEditStore = useContentEditStore();
  return <div className="GlobalEditControls flex flex-col gap-1 m-1 font-montserrat" data-sentry-component="GlobalEditControls" data-sentry-source-file="GlobalEditControls.tsx">
      {editStatus === "selecting" && <>
          <button className={clsx(ADMIN_STYLE.buttonShapeSmall, ADMIN_STYLE.buttonActionColors)} onClick={() => contentEditStore?.showSearch("text")}>
            Search Website
          </button>
          <button onClick={() => onSelecting && onSelecting(false)} className={clsx(ADMIN_STYLE.buttonShapeSmall, ADMIN_STYLE.buttonBasicColors, "bg-white")}>
            <XMarkIcon className="w-5 h-5" />
            Dismiss
          </button>
          <button onClick={() => onConfirmingHide && onConfirmingHide(false)} className={clsx(ADMIN_STYLE.buttonShapeSmall, ADMIN_STYLE.buttonBasicColors, "bg-white")}>
            <TrashIcon className="w-5 h-5" />
            Hide
          </button>
        </>}

      {(editStatus === "inactive" || !editStatus) && <button onClick={() => onSelecting && onSelecting(true)} className={clsx(ADMIN_STYLE.buttonShapeSmall, ADMIN_STYLE.buttonBasicColors, `
              bg-white
              opacity-80
              hover:opacity-100
            `)}>
          <img src="https://mp1md-pub.s3.amazonaws.com/groupflow/images/groupflow-icon.svg" className="w-5 h-5" alt="GroupFlow Icon" />
          <span className="text-secondary-800">Edit Mode</span>
        </button>}
    </div>;
}
export default observer(GlobalEditControls);
import { action, makeObservable, observable, runInAction } from "mobx";
import phoenixTypes from "types/phoenix";
import { connectWebSocket } from "lib/gf-websocket";
import { claimsAreValid, jwtIsValid } from "lib/jwt";
import JwtStore from "./JwtStore";

export default class WebSocketStore {
  jwtStore: JwtStore;
  groupSlug: string;
  socketUrl: string;
  startedConnectingWebSocket: boolean;
  webSocket: phoenixTypes.Socket | null;

  constructor(wsBaseUrl: string, jwtStore: JwtStore, groupSlug: string) {
    this.jwtStore = jwtStore;
    this.groupSlug = groupSlug;
    this.socketUrl = wsBaseUrl;
    this.startedConnectingWebSocket = false;
    this.webSocket = null;

    makeObservable(this, {
      webSocket: observable,
      getWebSocket: action,
    });
  }

  getWebSocket() {
    if (typeof window === "undefined") return null;
    if (this.webSocket) return this.webSocket;

    const jwt = this.jwtStore.memberJwt;
    if (!jwt) {
      console.warn("Attempt to connect to WebSocket without JWT");
      return null;
    }
    if (!jwtIsValid(jwt)) {
      console.warn("Attempt to connect to WebSocket with invalid JWT");
      return null;
    }

    if (!this.webSocket && !this.startedConnectingWebSocket) {
      this.startedConnectingWebSocket = true;
      this.webSocket = connectWebSocket(this.socketUrl, jwt, this.groupSlug);
      (this.webSocket as any).onError(() => {
        let disconnect = false;
        if (
          this.jwtStore.validClaims &&
          !claimsAreValid(this.jwtStore.validClaims)
        ) {
          disconnect = true;
        } else if (!this.jwtStore.validClaims) {
          disconnect = true;
        }

        if (disconnect) {
          console.log("JWT is no longer valid. Disconnecting.");
          (this.webSocket as any).disconnect();
          runInAction(() => {
            this.webSocket = null;
          });
        }
      });

      (this.webSocket as any).onClose(() => {
        runInAction(() => {
          this.webSocket = null;
          this.startedConnectingWebSocket = false;
        });
      });
    }

    return this.webSocket;
  }

  disconnect() {
    if (this.webSocket) {
      (this.webSocket as any).disconnect();
      runInAction(() => {
        this.webSocket = null;
        this.startedConnectingWebSocket = false;
      });
    }
  }
}

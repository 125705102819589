import { makeAutoObservable } from "mobx";
import JwtStore from "./JwtStore";
import UserStore from "./UserStore";
import ChannelStore from "./ChannelStore";
import ContentEditStore from "./ContentEditStore";
import ContentStore from "./ContentStore";
import HamburgerStore from "./HamburgerStore";
import LoginModalStore from "./LoginModalStore";
import AlertStore from "./AlertStore";
import StripeStore from "./StripeStore";
import FormStore from "./FormStore";
import PasswordSetModalStore from "./PasswordSetModalStore";
import EmailSetModalStore from "./EmailSetModalStore";
import WebSocketStore from "./WebSocketStore";

interface RootStoreConfig {
  apiBaseUrl?: string;
  groupConfig?: any;
  groupSlug?: string;
  jwt?: string;
  wsBaseUrl?: string;
  uri?: string;
}

export class RootStore {
  alertStore: AlertStore;
  channelStore: ChannelStore | null;
  contentEditStore: ContentEditStore | undefined;
  contentStore: ContentStore | null;
  emailSetModalStore: EmailSetModalStore;
  formStore: FormStore;
  hamburgerStore: HamburgerStore;
  jwtStore: JwtStore;
  loginModalStore: LoginModalStore;
  passwordSetModalStore: PasswordSetModalStore;
  stripeStore: StripeStore;
  userStore: UserStore | null;
  webSocketStore: WebSocketStore | null;

  constructor(config: RootStoreConfig) {
    const { apiBaseUrl, wsBaseUrl, jwt, groupSlug, groupConfig } = config;

    // Initialize required stores
    this.alertStore = new AlertStore();
    this.channelStore = null;
    this.contentEditStore = undefined;
    this.contentStore = null;
    this.emailSetModalStore = new EmailSetModalStore();
    this.webSocketStore = null;
    this.formStore = new FormStore({
      group: groupConfig || { slug: "", name: "" },
    });
    this.hamburgerStore = new HamburgerStore();
    this.jwtStore = new JwtStore(jwt);
    this.loginModalStore = new LoginModalStore();
    this.passwordSetModalStore = new PasswordSetModalStore();
    this.stripeStore = new StripeStore();
    this.userStore = null;

    // Initialize stores based on configuration
    if (apiBaseUrl && groupSlug) {
      this.userStore = new UserStore(apiBaseUrl, this.jwtStore, groupSlug);

      if (wsBaseUrl) {
        this.webSocketStore = new WebSocketStore(
          wsBaseUrl,
          this.jwtStore,
          groupSlug
        );
        this.channelStore = new ChannelStore(
          this.webSocketStore,
          this.jwtStore
        );
      }

      this.contentEditStore = new ContentEditStore({
        baseUrl: apiBaseUrl,
        jwt: jwt || "",
        groupSlug,
      });
    }

    if (groupConfig) {
      this.contentStore = new ContentStore({
        baseUrl: apiBaseUrl,
        groupSlug,
        jwt,
        groupConfig,
      });
      this.formStore = new FormStore({ group: groupConfig });
      this.stripeStore = new StripeStore();
    }

    // Make the root store observable
    makeAutoObservable(this);

    // Initialize stores that need immediate data loading
    if (this.jwtStore?.validJwt && this.userStore) {
      this.userStore.loadUser();
    }

    if (this.channelStore && this.jwtStore?.memberJwt) {
      this.channelStore.getWebSocket();
      this.channelStore.loadMemberChannelAndConversations();
    }

    if (this.contentStore) {
      this.contentStore.loadNavigationData();
    }

    if (this.formStore) {
      this.formStore.loadData();
    }
  }
}

/*!
 * The side car menu displays the content of the PrimaryNavigation, for mobile devices only.
 */

import SideCar from "components/nav/SideCar";
import { useHamburgerStore } from "stores";
import { observer } from "mobx-react-lite";
function SideCarMenu() {
  const store = useHamburgerStore();
  return <>
      {store?.isOpen && <div className="absolute inset-0 z-10 cursor-e-resize" style={{
      transform: "translatex(-16rem) translatez(0)"
    }} onClick={() => store.close()}>
          &nbsp;
        </div>}
      <SideCar data-sentry-element="SideCar" data-sentry-source-file="SideCarMenu.tsx" />
    </>;
}
export default observer(SideCarMenu);
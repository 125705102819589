// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { createContext, useContext } from "react";
import AppContextType from "types/gf-app-context";

export const GfAppContext = createContext<Partial<AppContextType>>({});

export function useAppContext(): Partial<AppContextType> {
  return useContext(GfAppContext);
}

import { observer } from "mobx-react-lite";
import { formatDate } from "lib/date";
import { useGroupConfig } from "hooks";
interface Props {
  className?: string;
  parentTag?: string;
}
const GroupFooterCopyright = (props: Props) => {
  const {
    className,
    parentTag
  } = props;
  const group = useGroupConfig();
  return <div className={`${parentTag} GroupCopyright ${className || ""}`} data-sentry-component="GroupFooterCopyright" data-sentry-source-file="GroupCopyright.tsx">
      &copy; {formatDate(new Date(), "yyyy")} {group.name}
    </div>;
};
export default observer(GroupFooterCopyright);
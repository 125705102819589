import ActionButton from "components/admin/ui/ActionButton";
import GroupFlowWideLogo from "components/icon/GroupFlowWideLogo";
import SimpleModal from "components/SimpleModal";
import { useGroupConfig } from "hooks";
interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}
export default function AboutGroupFlowModal(props: Props) {
  const {
    isOpen,
    onClose
  } = props;
  const group = useGroupConfig();
  return <SimpleModal isOpen={isOpen} onClose={onClose} themeArea="admin" data-sentry-element="SimpleModal" data-sentry-component="AboutGroupFlowModal" data-sentry-source-file="AboutGroupFlowModal.tsx">
      <div className="flex flex-col gap-y-4 -mt-4 mb-4">
        <a className="flex justify-center" href={`https://www.groupflow.app/?utm_source=group-site&utm_medium=web&utm_content=about-groupflow&utm_term=${group.slug}`} rel="external">
          <GroupFlowWideLogo className="max-w-[15rem] fill-current w-full text-gf-green h-auto" data-sentry-element="GroupFlowWideLogo" data-sentry-source-file="AboutGroupFlowModal.tsx" />
        </a>
        <div className="flex flex-col gap-y-4 mt-4">
          <p>{group.name} proudly uses GroupFlow for its:</p>
          <ul className="list-inside list-disc">
            <li>Website</li>
            <li>Member platform</li>
            <li>Events platform</li>
          </ul>
          <p>
            GroupFlow empowers groups to create, manage, and grow their
            in-person community.
          </p>
          <p>
            <a href={`https://www.groupflow.app/affiliate?utm_source=group-site&utm_medium=web&utm_content=affiliate&utm_term=${group.slug}`} className="a font-medium underline" target="_blank" rel="noreferrer noopener">
              Earn revenue
            </a>{" "}
            by referring other groups to GroupFlow.
          </p>
        </div>
        <div className="flex justify-center">
          <ActionButton href={`https://www.groupflow.app/?utm_source=group-site&utm_medium=web&utm_content=about-groupflow&utm_term=${group.slug}`} data-sentry-element="ActionButton" data-sentry-source-file="AboutGroupFlowModal.tsx">
            More about GroupFlow
          </ActionButton>
        </div>
      </div>
    </SimpleModal>;
}
import { useEffect, useState } from "react";
import { Me, Role } from "types/member";
import { useCookies } from "react-cookie";
import { jwtCookieName } from "lib/constants";
import { useRouter } from "next/router";
import { MeetupConfig, MeetupIntegration, GroupConfig } from "types/group";
import { URI } from "types/web";
import { AuthErrorType, AuthResult } from "types/auth";
import { ApiCall } from "types/api";
import { useUserStore } from "stores";
import {
  getApiBaseUrl,
  getBackendBaseUrl,
  getRootUrl,
} from "lib/gf-api/api-util";
import { useAppContext } from "lib/gf-app-context";
import { memberHasRoleAccess } from "lib/auth";
import { isWebSite2 } from "lib/web-component";

export function useApiBaseUrl(): string {
  const uri = useURI();
  return getApiBaseUrl(uri);
}

export function useWebBaseUrl() {
  const uri = useURI();
  return getRootUrl(uri);
}

export function useBackendBaseUrl() {
  const uri = useURI();
  return getBackendBaseUrl(uri);
}

export function useNavItemUrl(itemUri: string) {
  const uri = useURI();
  const backendBaseUrl = getBackendBaseUrl(uri);

  if (itemUri.match(/^https?:/)) {
    return itemUri;
  }

  return itemUri.replace("{backendBaseUrl}", backendBaseUrl);
}

export function useURI(): URI {
  const { uri } = useAppContext();
  if (!uri) throw new Error("uri is not set");
  return uri;
}

export function useMeetupOAuthUrl(referrer: string) {
  const group = useGroupConfig();
  const backendBaseUrl = useBackendBaseUrl();
  return (
    backendBaseUrl +
    `/browser/oauth-redirect?group=${group.slug}&referrer=${encodeURIComponent(
      referrer
    )}`
  );
}

export function useMemberJwt(): string | null {
  const { jwtStore } = useAppContext();
  return jwtStore?.memberJwt || null;
}

export function useAnyValidJwt(): string | null {
  const { jwtStore } = useAppContext();
  return jwtStore?.validJwt || null;
}

export function useUserId(): string | null {
  const member = useMember();
  return member?.account.id || null;
}

export function useMember(): Me | null {
  const userStore = useUserStore();
  return userStore.user || null;
}

export function useMeetupConfig(): MeetupConfig | undefined {
  const { integrations } = useGroupConfig();

  const meetupIntegration = integrations.find((i) => i.name === "meetup") as
    | MeetupIntegration
    | undefined;

  if (!meetupIntegration) {
    return undefined;
  }

  return meetupIntegration.config;
}

export function useGroupConfig(): GroupConfig {
  const appContext = useAppContext();
  const { groupConfig } = appContext;
  if (!groupConfig) throw new Error("groupConfig not set");
  return groupConfig;
}

export function useEventTagGroups() {
  const group = useGroupConfig();
  return group.tagGroups.filter((tg) => tg.purpose === "EVENT");
}

export function useApiCallParams(groupSlug?: string): ApiCall {
  const appContext = useAppContext();
  const { sessionId } = appContext;
  groupSlug = groupSlug || appContext.groupSlug;
  if (!groupSlug) throw new Error("useApiCallParams() groupSlug is not set");
  const jwt = useAnyValidJwt();
  const baseUrl = useApiBaseUrl();
  return { baseUrl, groupSlug, jwt, sessionId };
}

export function useRemoveJwtCookie() {
  const cookiesUse = useCookies([jwtCookieName]);
  const removeCookie = cookiesUse[2];
  return () => {
    removeCookie(jwtCookieName);
    if (typeof document !== "undefined") {
      document.cookie = `${jwtCookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  };
}

export function useRequireMemberToContinue(props?: { redirect?: boolean }) {
  const { redirect } = props || {};
  const router = useRouter();
  const { jwtStore } = useAppContext();
  const jwt = jwtStore?.memberJwt;

  useEffect(() => {
    if (redirect !== false && !jwt) {
      router.push(`/login?ret=${encodeURIComponent(router.asPath)}`);
    }
  }, [false]);

  return jwt;
}

export function useValidateUserRole(role: Role): AuthResult {
  const { jwtStore } = useAppContext();
  const jwt = jwtStore?.memberJwt;
  const user = useMember();
  const hasAccess = user ? memberHasRoleAccess(user.account, role) : false;
  const router = useRouter();
  let type: undefined | AuthErrorType;
  if (!jwt) {
    type = "unauthenticated";
  } else if (!user) {
    type = "loading";
  } else if (!hasAccess) {
    type = "unauthorized";
  }
  const [state, setState] = useState<AuthResult>({
    valid: !type,
    type: type ?? null,
  });

  useEffect(() => {
    if (type === "unauthenticated") {
      router.push(`/login?ret=${encodeURIComponent(router.asPath)}`);
    }
    const newState: AuthResult = { valid: !type, type: type ?? null };
    setState(newState);
  }, [type]);

  return state;
}

// Calls useEffect(), but only calls the callback when the user is authorized.
export function useAuthorizedEffect(
  callback: () => void,
  authResult: AuthResult,
  changeFlags?: any[]
) {
  changeFlags = [authResult.valid, ...(changeFlags || [])];
  useEffect(() => {
    if (authResult.valid) callback();
  }, changeFlags);
}

type EngineType = "themed" | "web-components";
interface PageEngine {
  type: EngineType;
  version: number;
}

export function usePageEngine(): PageEngine {
  const groupConfig = useGroupConfig();
  const isSite2 = isWebSite2(groupConfig);
  let type: EngineType = "themed";
  let version = 1;

  if (isSite2) {
    type = "web-components";
    version = 2;
  } else {
    throw new Error(
      `Engine type not detected for groupSlug=${groupConfig.slug}`
    );
  }

  return { type, version };
}

interface Props {
  style?: any;
  className?: string;
}
export default function TwitterIcon(props: Props) {
  const {
    className,
    style
  } = props;
  const otherProps = ({} as any);
  return <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" style={style} className={className} {...otherProps} data-sentry-element="svg" data-sentry-component="TwitterIcon" data-sentry-source-file="TwitterIcon.tsx">
      <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" data-sentry-element="path" data-sentry-source-file="TwitterIcon.tsx" />
    </svg>;
}
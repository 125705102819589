// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ItemSearch from "components/ItemSearch";
import { useApiCallParams, useMemberJwt } from "hooks";
import { listContentItems2 } from "lib/gf-api/content-api";
import { useState } from "react";
import { ContentItem } from "types/content";
import TextItemResultItem from "./TextItemResultItem";
interface Props {
  onItemSelected?: (navItem: ContentItem) => void;
}
export default function TextItemsSearch(props: Props) {
  const jwt = useMemberJwt();
  if (!jwt) return null;
  const apiProps = {
    ...useApiCallParams(),
    jwt
  };
  const [items, setItems] = useState<null | ContentItem[]>(null);
  function onSearch(queryString: string) {
    listContentItems2(apiProps, {
      baseType: "text",
      queryString
    }).then(result => {
      if (!result.error) {
        setItems((result.data as ContentItem[]));
      }
    });
  }
  return <ItemSearch onSearch={onSearch} onItemSelected={props.onItemSelected} items={items} mapResultItem={item => <TextItemResultItem contentItem={item} />} data-sentry-element="ItemSearch" data-sentry-component="TextItemsSearch" data-sentry-source-file="TextItemsSearch.tsx" />;
}
import { useMember } from "hooks";
import { useEffect, useState } from "react";

const useHomeWelcomeBack = () => {
  const member = useMember();
  const [modalOpen, setModalOpen] = useState<boolean>(!!member);

  useEffect(() => {
    if (member) {
      setModalOpen(true);
    }
  }, [member?.account?.id]);

  return {
    member,
    modalOpen,
    onClose: () => setModalOpen(false),
    setModalOpen,
  };
};

export default useHomeWelcomeBack;

import clsx from "clsx";
import EditableSection from "components/contentEditing/EditableSection";
import BlueskyIcon from "components/icon/BlueskyIcon";
import DiscordIcon from "components/icon/DiscordIcon";
import FacebookIcon from "components/icon/FacebookIcon";
import InstagramIcon from "components/icon/InstagramIcon";
import TwitterIcon from "components/icon/TwitterIcon";
import { usePosts } from "hooks/content";
import { observer } from "mobx-react-lite";
import { ContentItem, SimplifiedContent } from "types/content";
interface Props {
  className?: string;
  postSlug?: string;
}
type SocialPlatformSlug = "instagram" | "facebook" | "bluesky" | "discord" | "x-twitter";
interface SocialPlatform {
  name: string;
  slug: SocialPlatformSlug;
  component: React.ComponentType<any>;
}
const SOCIAL_PLATFORMS: SocialPlatform[] = [{
  name: "Instagram",
  slug: "instagram",
  component: InstagramIcon
}, {
  name: "Facebook",
  slug: "facebook",
  component: FacebookIcon
}, {
  name: "Bluesky",
  slug: "bluesky",
  component: BlueskyIcon
}, {
  name: "Discord",
  slug: "discord",
  component: DiscordIcon
}, {
  name: "X-Twitter",
  slug: "x-twitter",
  component: TwitterIcon
}];
function SocialLinks(props: Props) {
  const {
    className,
    postSlug
  } = props;
  const {
    getPostListBySlug: getPost
  } = usePosts();
  const postsItem = postSlug ? getPost(postSlug) : undefined;
  const links = postsItem?.children || [];
  const platforms = links.reduce((acc, link) => {
    const platform = SOCIAL_PLATFORMS.find(platform => platform.slug === link.purpose);
    if (link.content && link.purpose && platform) {
      acc = [...acc, {
        link,
        platform
      }];
    }
    return acc;
  }, ([] as {
    link: ContentItem;
    platform: SocialPlatform;
  }[]));
  return <EditableSection editById={postsItem ? {
    id: postsItem?.id,
    baseType: "text",
    webComponentName: "SocialLinks"
  } : undefined} editBySlug={postSlug && !postsItem ? {
    slug: postSlug,
    baseType: "text",
    subType: "post",
    plurality: "item",
    webComponentName: "SocialLinks"
  } : undefined} className={clsx(className, "social-links-content")} componentType="ul" data-sentry-element="EditableSection" data-sentry-component="SocialLinks" data-sentry-source-file="SocialLinks.tsx">
      {platforms.map(item => <li key={item.platform.slug}>
          <a href={item.link.content} rel="noreferrer noopener" target="_blank">
            <item.platform.component className="image fill-current" />
          </a>
        </li>)}
    </EditableSection>;
}
export default observer(SocialLinks);
export const SOCIAL_LINKS_ATTRIBUTES = [{
  key: "postSlug",
  type: "string"
}];
export const SOCIAL_LINKS_CONTENT_SCHEMA: SimplifiedContent = {
  isParentEditable: false,
  items: [{
    purpose: "instagram",
    title: "Instagram Link",
    type: "text"
  }, {
    purpose: "facebook",
    title: "Facebook Link",
    type: "text"
  }, {
    purpose: "bluesky",
    title: "Bluesky Link",
    type: "text"
  }, {
    purpose: "discord",
    title: "Discord Link",
    type: "text"
  }, {
    purpose: "x-twitter",
    title: "X-Twitter Link",
    type: "text"
  }]
};